import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from '../Home/Header'
import { Footer } from '../Home/Footer'
import { YMInitializer } from 'react-yandex-metrika';
// import Modal from '../Modal'

const MainLayout: React.FC = () => {
    return (
        <div className='wrapper'>
            <YMInitializer accounts={[17994568]} options={{ webvisor: true, clickmap: true, defer: true}} />
            <Header />
            {/*<Modal />*/}

            <Outlet />

            <Footer />
        </div>
    )
}

export default MainLayout
