import React from 'react'
import { Helmet } from 'react-helmet'
import { Content } from '../components/Home/Content'
import '../App.css'
import '../scss/home.scss'

export const Home: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Гидравлика, пневматика, запорная арматура</title>
                <meta name='description'
                      content='Компания ЕвроПромГидравлик предлагает гидроцилиндры и маслостанции собственного производства, гидрооборудование, гидроагрегаты, проектирование и производство гидравлических приводов и оборудования, рвд, гидравлические шланги, адаптеры и пр.' />
                <meta name='keywords'
                      content='Гидрооборудование, гидроцилиндры, гидроагрегаты, маслостанции ЕПГ г. Санкт-Петербург, насосы, гидроприводы, РВД, ATOS, Hytar, Marzocchi, гидравлика, магазин гидравлики, гидро, труба, купить трубу, шток, адаптеры' />
            </Helmet>
            <div className='content'>
                <Content />
            </div>
        </>
    )
}